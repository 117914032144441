<template>
  <svg
    width="92"
    height="13"
    viewBox="0 0 92 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group">
      <g id="Group_2">
        <g id="Group_3">
          <path
            id="Vector"
            d="M30.5859 0H33.154V4.4252C33.8139 3.6088 34.9522 3.0888 36.3105 3.0888C38.6036 3.0888 40.2754 4.5448 40.2754 7.202V12.844H37.7073V7.5348C37.7073 5.9904 36.8054 5.1584 35.4471 5.1584C34.0504 5.1584 33.154 5.9904 33.154 7.5348V12.844H30.5859V0Z"
            fill="white"
          />
          <path
            id="Vector_2"
            d="M51.8565 12.844H49.2665V11.6272C48.6231 12.4436 47.5067 12.9636 46.2365 12.9636C43.8663 12.9636 42.1616 11.5076 42.1616 8.86605V3.22925H44.7352V8.52285C44.7352 10.0672 45.6371 10.8992 46.9953 10.8992C48.3921 10.8992 49.272 10.0672 49.272 8.52285V3.22925H51.862V12.844H51.8565Z"
            fill="white"
          />
          <path
            id="Vector_3"
            d="M57.9329 3.0732C59.2747 3.0732 60.556 3.6608 61.2544 4.5656V0H63.8609V12.844H61.2544V11.4192C60.6495 12.3032 59.5111 13 57.9164 13C55.3263 13 53.2752 11.0032 53.2752 8.0028C53.2697 4.9972 55.3263 3.0732 57.9329 3.0732ZM58.5763 5.2052C57.2015 5.2052 55.8982 6.1776 55.8982 7.9976C55.8982 9.8176 57.2015 10.8628 58.5763 10.8628C59.9896 10.8628 61.2764 9.854 61.2764 8.034C61.2764 6.214 59.9896 5.2052 58.5763 5.2052Z"
            fill="white"
          />
          <path
            id="Vector_4"
            d="M69.9209 3.0732C71.2627 3.0732 72.544 3.6608 73.2424 4.5656V0H75.849V12.844H73.2424V11.4192C72.6375 12.3032 71.4992 13 69.9044 13C67.3144 13 65.2632 11.0032 65.2632 8.0028C65.2577 4.9972 67.3144 3.0732 69.9209 3.0732ZM70.5588 5.2052C69.1841 5.2052 67.8808 6.1776 67.8808 7.9976C67.8808 9.8176 69.1841 10.8628 70.5588 10.8628C71.9721 10.8628 73.2589 9.854 73.2589 8.034C73.2589 6.214 71.9721 5.2052 70.5588 5.2052Z"
            fill="white"
          />
          <path
            id="Vector_5"
            d="M77.9058 0H80.4739V12.844H77.9058V0Z"
            fill="white"
          />
          <path
            id="Vector_6"
            d="M86.9901 13C84.0371 13 81.887 11.0552 81.887 8.03399C81.887 4.99719 83.9766 3.06799 86.9901 3.06799C89.9267 3.06799 91.9998 4.96079 91.9998 7.82599C91.9998 8.13799 91.9833 8.44999 91.9283 8.76199H84.4935C84.62 10.1868 85.6484 10.9824 86.9351 10.9824C88.035 10.9824 88.6399 10.4624 88.9698 9.81759H91.7414C91.1915 11.596 89.5032 13 86.9901 13ZM84.51 7.13439H89.3382C89.2998 5.86559 88.2384 5.06999 86.9131 5.06999C85.6868 5.06999 84.7135 5.81359 84.51 7.13439Z"
            fill="white"
          />
        </g>
        <g id="Group_4">
          <g id="Group_5">
            <path
              id="Vector_7"
              d="M25.6423 0H26.6102V0.2808H26.3187V1.2116H25.9338L25.9393 0.2808H25.6423V0ZM26.8136 0H27.3196L27.6275 0.78L27.963 0H28.4359V1.2168H28.0839L28.0784 0.4524L27.7485 1.2168H27.49L27.1601 0.4264V1.2168H26.8191V0H26.8136Z"
              fill="#B9A096"
            />
          </g>
          <g id="Group_6">
            <path
              id="Vector_8"
              d="M18.653 3.432V0H16.085V2.34C16.9868 2.6208 17.8447 2.99 18.653 3.432Z"
              fill="#B9A096"
            />
            <path
              id="Vector_9"
              d="M17.3716 8.9128C17.2781 8.8296 17.1791 8.7516 17.0857 8.6736C17.0747 8.6684 17.0692 8.658 17.0582 8.6528C16.9647 8.58 16.8712 8.5072 16.7722 8.4344C16.7557 8.424 16.7447 8.4136 16.7282 8.4032C16.6347 8.3356 16.5357 8.268 16.4368 8.2056C16.4203 8.1952 16.4093 8.1848 16.3928 8.1796C16.2938 8.1172 16.1893 8.0548 16.0848 7.9924L16.0793 7.9872H7.70968V0H5.1416V12.844H7.70968V10.4156H16.0848V12.844H18.6529V10.3688C18.29 9.8332 17.8555 9.3444 17.3716 8.9128Z"
              fill="#B9A096"
            />
            <path
              id="Vector_10"
              d="M21.221 0V7.2696C19.0103 4.7112 15.6504 3.0784 11.8945 3.0784C10.8937 3.0784 9.92032 3.1928 8.99097 3.4112V5.9384C9.90382 5.6576 10.8827 5.5068 11.8945 5.5068C16.5357 5.5068 20.4016 8.6736 21.2155 12.844H23.7946V0H21.221Z"
              fill="#B9A096"
            />
            <path
              id="Vector_11"
              d="M2.56808 7.2696V0H0V12.844H2.57908C2.79904 11.7208 3.23897 10.6704 3.85487 9.7344V5.9956C3.39295 6.3908 2.96402 6.8172 2.56808 7.2696Z"
              fill="#B9A096"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
