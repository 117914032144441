<template>
  <div class="public-layout">
    <div
      :class="[
        'public-layout__column',
        { 'public-layout__column--is-centered': isDesktop },
        { 'public-layout__column--is-aside': isDesktop },
      ]"
    >
      <div class="public-layout__brand">
        <BackgroundLogoMobile
          v-if="isMobile"
        />
        <BackgroundLogo
          v-else
        />
      </div>
    </div>
    <div
      :class="[
        'public-layout__column',
        'public-layout__column--is-main',
        { 'public-layout__column--is-centered': isDesktop },
      ]"
    >
      <div class="public-layout__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { useBreakpoints } from '@/composables';
import BackgroundLogo from '@/components/svg/BackgroundLogo';
import BackgroundLogoMobile from '@/components/svg/BackgroundLogoMobile';

export default {
  components: {
    BackgroundLogo,
    BackgroundLogoMobile,
  },
  setup() {
    const { isMobile, isDesktop } = useBreakpoints({
      minDesktopWidth: 650,
      maxMobileWidth: 649,
    });

    return {
      isMobile,
      isDesktop,
    };
  },
};
</script>

<style lang="scss">
.public-layout {
  display: flex;
  flex-direction: column;
  height: 100dvh;

  &__column {
    display: flex;

    &--is-centered {
      align-items: center;
      justify-content: center;
    }

    &--is-main {
      flex: 1;
    }

    &--is-aside {
      flex-basis: 35%;
      max-width: 627px;
    }
  }

  &__brand {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 16px;
    height: 90px;
    width: 100%;
    background: url(/images/mobileBg.png) repeat 0 0;
    background-size: cover;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 48px 16px 16px;
  }
}

@media screen and (min-width: 650px) {
  .public-layout {
    flex-direction: row;

    &__brand {
      height: 100%;
      padding: 0 32px;
      align-items: center;
      background: url(/images/desktopBg.png) repeat 0 0;
      background-size: cover;
    }

    &__content {
      height: auto;
      border-radius: 8px;
      border: 1px solid #F0F0F0;
      background: #FFF;
      box-shadow: 0px 4px 8px 1px rgba(25, 20, 20, 0.09);
      padding: 32px 40px;
      margin: 0 32px;
      max-width: 480px;
    }
  }
}
</style>
