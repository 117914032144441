<template>
  <svg
    class="background-logo"
    viewBox="0 0 142 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 96.2943H5.92705V107.093C7.44941 105.104 10.0679 103.825 13.2141 103.825C18.5119 103.825 22.3482 107.377 22.3482 113.852V127.594H16.4212V114.644C16.4212 110.868 14.3508 108.839 11.2249 108.839C8.01776 108.839 5.92705 110.868 5.92705 114.644V127.594H0V96.2943Z"
      fill="white"
    />
    <path
      d="M49.0403 127.594H43.0726V124.63C41.5908 126.62 39.013 127.898 36.09 127.898C30.6299 127.898 26.692 124.346 26.692 117.912V104.17H32.6191V117.08C32.6191 120.855 34.6895 122.885 37.8154 122.885C41.0225 122.885 43.0523 120.855 43.0523 117.08V104.17H49.02V127.594H49.0403Z"
      fill="white"
    />
    <path
      d="M63.046 103.784C66.1313 103.784 69.0948 105.225 70.6984 107.418V96.2943H76.7066V127.614H70.6984V124.143C69.2978 126.295 66.6794 128 63.0054 128C57.0378 128 52.3083 123.128 52.3083 115.821C52.288 108.473 57.0378 103.784 63.046 103.784ZM64.5278 108.981C61.3613 108.981 58.3571 111.356 58.3571 115.801C58.3571 120.246 61.3613 122.783 64.5278 122.783C67.7958 122.783 70.739 120.327 70.739 115.882C70.739 111.437 67.7755 108.981 64.5278 108.981Z"
      fill="white"
    />
    <path
      d="M90.6718 103.784C93.7571 103.784 96.7206 105.225 98.3242 107.418V96.2943H104.332V127.614H98.3242V124.143C96.9236 126.295 94.3052 128 90.6312 128C84.6635 128 79.9341 123.128 79.9341 115.821C79.9341 108.473 84.6636 103.784 90.6718 103.784ZM92.1536 108.981C88.9871 108.981 85.9829 111.356 85.9829 115.801C85.9829 120.246 88.9871 122.783 92.1536 122.783C95.4216 122.783 98.3648 120.327 98.3648 115.882C98.3851 111.437 95.4216 108.981 92.1536 108.981Z"
      fill="white"
    />
    <path
      d="M109.082 96.2943H115.009V127.614H109.082V96.2943Z"
      fill="white"
    />
    <path
      d="M130.03 127.98C123.21 127.98 118.257 123.25 118.257 115.882C118.257 108.473 123.088 103.784 130.03 103.784C136.809 103.784 141.579 108.392 141.579 115.375C141.579 116.146 141.539 116.897 141.417 117.668H124.285C124.59 121.139 126.944 123.088 129.908 123.088C132.445 123.088 133.846 121.809 134.597 120.246H140.991C139.712 124.549 135.815 127.98 130.03 127.98ZM124.306 113.67H135.449C135.368 110.584 132.912 108.636 129.867 108.636C127.026 108.636 124.773 110.462 124.306 113.67Z"
      fill="white"
    />
    <path
      d="M128.65 86.5715H133.054V87.9315H131.715V92.4174H129.969L129.989 87.9315H128.65V86.5715ZM133.968 86.5715H136.282L137.702 90.3267L139.225 86.5715H141.397V92.4377H139.773L139.732 88.7637L138.21 92.4377H137.033L135.51 88.6216V92.4377H133.947V86.5715H133.968Z"
      fill="#B9A096"
    />
    <path
      d="M110.848 21.5566V0H95.5839V14.7161C100.943 16.4821 106.058 18.7758 110.848 21.5566Z"
      fill="#B9A096"
    />
    <path
      d="M103.216 56.0228C102.648 55.4951 102.079 55.0079 101.511 54.5005C101.45 54.4599 101.389 54.399 101.349 54.3584C100.8 53.8915 100.232 53.445 99.6638 52.9984C99.5826 52.9375 99.5014 52.8766 99.3999 52.7954C98.8316 52.3692 98.2632 51.9429 97.6746 51.537C97.5934 51.4761 97.4919 51.4152 97.4107 51.3543C96.8221 50.9483 96.2131 50.5626 95.6042 50.177C95.5839 50.177 95.5636 50.1567 95.5636 50.1567H45.8332V0H30.569V80.7257H45.8332V65.4615H95.6042V80.7257H110.868V65.157C108.696 61.7875 106.119 58.7225 103.216 56.0228Z"
      fill="#B9A096"
    />
    <path
      d="M126.133 0V45.6911C113 29.615 93.0263 19.3441 70.6984 19.3441C64.751 19.3441 58.966 20.0749 53.4449 21.4551V37.3283C58.8848 35.5826 64.6901 34.6286 70.6984 34.6286C98.2632 34.6286 121.261 54.5411 126.072 80.746H141.397V0H126.133Z"
      fill="#B9A096"
    />
    <path
      d="M15.2642 45.6911V0H0V80.7257H15.3251C16.6242 73.6619 19.2426 67.065 22.9166 61.1989V37.6733C20.156 40.1497 17.5985 42.8291 15.2642 45.6911Z"
      fill="#B9A096"
    />
  </svg>
</template>

<style lang="scss">
.background-logo {
  height: 8rem;
  width: 8.875rem;
}
</style>
